import { useEffect, useState } from 'react';
import moment from 'moment';
import { PageHeader, Row, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash/collection';
import { sumBy } from 'lodash';
import withAuthLayout from '@/layouts/withAuthLayout';
import ApiClient from '@/helpers/apiClient/ApiClient';
import { Main } from '@/container/styled';
import { HeaderWrapper } from '@/components/statistic/siteStatistic/calendar/styled';
import Calendar from '@/components/statistic/siteStatistic/calendar';
import useDateRange from '@/hooks/useDateRange';

const { Text } = Typography;

const renderSum = (value) => {
  return <span>₴ {Intl.NumberFormat('uk-UA').format(value)}</span>;
};

const expandedRowRenderDates = (record) => {
  const columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      render: (value) => moment(value).format('Y.MM.DD'),
    },
    {
      title: 'Тотал',
      dataIndex: 'revenue',
      render: renderSum,
    },
    {
      title: 'Онлайн',
      dataIndex: 'revenue_online',
      render: renderSum,
    },
    {
      title: 'Офлайн',
      dataIndex: 'revenue_offline',
      render: renderSum,
    },
  ];

  return <Table columns={columns} pagination={false} dataSource={record.data} rowKey="date" />;
};

const expandedRowRenderStores = (record) => {
  const columns = [
    {
      title: 'Торгова точка',
      dataIndex: 'store_name',
    },
    {
      title: 'Тотал',
      dataIndex: 'total',
      render: renderSum,
    },
    {
      title: 'Онлайн',
      dataIndex: 'online',
      render: renderSum,
    },
    {
      title: 'Офлайн',
      dataIndex: 'offline',
      render: renderSum,
    },
  ];

  return (
    <Table
      columns={columns}
      pagination={false}
      dataSource={record.stores}
      rowKey="store_id"
      expandable={{
        expandedRowRender: expandedRowRenderDates,
      }}
    />
  );
};

const Revenue = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();

  const { startDate, endDate, range, setRange } = useDateRange();

  useEffect(() => {
    setLoading(true);
    ApiClient.call('get', 'statistic/revenue', {
      start_date: moment(startDate).format('YYYY-MM-DD'),
      end_date: moment(endDate).format('YYYY-MM-DD'),
    })
      .data(({ data }) => {
        const arrayGroupCityData = Object.entries(groupBy(data, 'city_id')).map(([id, value]) => {
          return {
            city_id: id,
            city_name: value[0].city_name,
            total: sumBy(value, 'total'),
            online: sumBy(value, 'online'),
            offline: sumBy(value, 'offline'),
            stores: value,
          };
        });
        setData(arrayGroupCityData);
      })
      .finally(() => setLoading(false));
  }, [startDate, endDate]);

  const columns = [
    {
      title: 'Місто',
      dataIndex: 'city_name',
      key: 'city_name',
    },
    {
      title: 'Тотал',
      dataIndex: 'total',
      render: renderSum,
    },
    {
      title: 'Онлайн',
      dataIndex: 'online',
      render: renderSum,
    },
    {
      title: 'Офлайн',
      dataIndex: 'offline',
      render: renderSum,
    },
  ];

  return (
    <div>
      <HeaderWrapper>
        <PageHeader ghost title={t('revenue.list_page_header')} />
        <Calendar range={range} setRange={setRange} startDate={startDate} endDate={endDate} maxDate={moment().toDate()} />
      </HeaderWrapper>

      <Main>
        <Row gutter={[16, 16]}>
          <Table
            loading={loading}
            style={{ width: '100%' }}
            dataSource={data}
            columns={columns}
            pagination={false}
            rowKey="city_id"
            expandable={{
              expandedRowRender: expandedRowRenderStores,
            }}
            summary={(data) => {
              return (
                <>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Тотал</Table.Summary.Cell>
                    <Table.Summary.Cell index={1} />
                    <Table.Summary.Cell index={2}>
                      <Text type="success">{renderSum(sumBy(data, 'total'))}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      <Text type="success">{renderSum(sumBy(data, 'online'))}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      <Text type="success">{renderSum(sumBy(data, 'offline'))}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Row>
      </Main>
    </div>
  );
};

export default withAuthLayout(Revenue);
